import { useState } from "react";
import "./taskInputForm.styles.css";

const TaskInputForm = (props) => {
  const [todo, setTodo] = useState(null);
  const [todoExist, setTodoExist] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (todo === null || todo === "") {
      return;
    }
    if (props.todos.find((existingTodo) => existingTodo.taskTitle === todo)) {
      setTodoExist(true);
      return;
    }

    props.setTodos((prevState) => {
      return [{ taskTitle: todo.trim(), completed: false }, ...prevState];
    });
    setTodo(null);
    e.target.reset();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setTodoExist(false);

    setTodo(e.target.value);
  };

  return (
    <form className="task-input-form" onSubmit={handleSubmit}>
      <input
        type="search"
        className={`task-input-title ${
          todoExist ? "task-input-title-error" : ""
        }`}
        onChange={handleChange}
      />
      <button className="add-task" type="submit">
        Add Task
      </button>
      <p className={`${todoExist ? "task-exist" : "new-task"}`}>
        Task already exist
      </p>
    </form>
  );
};

export default TaskInputForm;
