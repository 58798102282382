import "./taskContainer.styles.css";

const TaskContainer = (props) => {
  const handleRemove = (e) => {
    e.preventDefault();
    const removedTodo = e.target.parentElement.childNodes[0].textContent;
    props.setTodos((prev) => {
      return prev.filter((todo) => todo.taskTitle !== removedTodo);
    });
  };

  const handleTaskComplete = (e) => {
    props.setTodos((prevState) => {
      return prevState.map((todo) => {
        if (todo.taskTitle === e.target.textContent) {
          return { taskTitle: todo.taskTitle, completed: !todo.completed };
        } else {
          return todo;
        }
      });
    });
  };

  return (
    <div className="task-container">
      {props.todos.map((todo, index) => (
        <div
          onClick={handleTaskComplete}
          className={`task-item ${todo.completed ? "completed" : ""}`}
          key={index}
        >
          <p className="task-title">{todo.taskTitle}</p>
          <span className="icon-remove" onClick={handleRemove}></span>
        </div>
      ))}
    </div>
  );
};

export default TaskContainer;
