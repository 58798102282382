import { useState, useEffect } from "react";
import TaskInputForm from "./components/task-input-form/taskInputForm.component";
import TaskContainer from "./components/task-container/taskContainer.component";
import "./App.css";

function App() {
  const [todos, setTodos] = useState(() => {
    const todosFromLocal = localStorage.getItem("todos");
    if (todosFromLocal) {
      return JSON.parse(todosFromLocal);
    } else {
      return [];
    }
  });

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  return (
    <div className="task-manager">
      <h1 className="main-heading"> Task Manager</h1>
      <TaskInputForm setTodos={setTodos} todos={todos} />
      <TaskContainer todos={todos} setTodos={setTodos} />
    </div>
  );
}

export default App;
